<template>
    <div class="row">
        <div class="col-12">
            <div class="form-group row mb-1">
                <label class="col-4 col-form-label" for="nis">NIS</label>
                <label class="col-8 col-form-label" for="nis">{{dataSiswa.siswa_nis}}</label>
            </div>

            <div class="form-group row mb-1">
                <label class="col-4 col-form-label" for="nama">Nama</label>
                <label class="col-8 col-form-label" for="nis">{{dataSiswa.siswa_nama}}</label>
            </div>
            <div class="form-group row mb-1">
                <label class="col-4 col-form-label" for="no_wa">No Whatsapp</label>
                <div class="col-8">
                    <input id="no_wa" type="text" class="form-control" name="no_wa"
                        v-model="no_wa" @keypress="isNumber($event)" autocomplete="off" />
                </div>
            </div>
            <!-- <div class="form-group row mb-3">
                <label class="col-4 col-form-label" for="email">Alamat Email</label>
                <div class="col-8">
                    <input id="email" type="email" name="email" class="form-control"
                        v-model="email" />
                </div>
            </div> -->
        </div>
        <!-- end col -->

        <div class="col-12 mt-2" v-show="promos.length > 0">
            <div v-if="promo_selected" class="alert alert-success text-center m-0">
                <label class="fw-bold m-0">{{detailPromoSelected.promo_nama}} <i role="button" @click="clearPromo()" class="fa fa-times ms-1"></i></label>
            </div>
            <div v-else class="alert alert-success text-center m-0" role="button" @click="openModalPromo()">
                <label class="fw-bold m-0" role="button">Promo</label>
            </div>
        </div>
        <div class="col-12 mt-2">
            <label class="title-kewajiban">Daftar Kewajiban Yang Harus Dibayar</label>
        </div>
        
        <div class="col-12 body-kewajiban">
            <div class="card" style="border: 1px solid #aaabad;" v-for="tagihan in tagihanBulanan" :key="tagihan.bayar_siswa_id">
                <div class="card-header">
                    <checkbox :checkbox="tagihan"></checkbox>
                    <small style="padding-left: 20px; font-weight: bold;" v-if="tagihan.kewajiban_keterangan">( {{tagihan.kewajiban_keterangan}} )</small>
                </div>
                <div class="card-body">
                    <div class="form-group row mb-0">
                        <label class="col-7 col-form-label">Nominal Tagihan</label>
                        <label class="col-5 col-form-label text-right">{{rupiah_pokok(tagihan.bayar_siswa_nominal)}}</label>
                    </div>
                    <template v-if="tagihan.ui_props.show_diskon == true">
                        <div class="form-group row" v-if="(tagihan.diskon_detil.length)">
                            <label class="col-12 col-form-label pb-0"><small>Diskon</small></label>
                        </div>
                        <div class="form-group row mb-0" v-for="(diskon, index) in tagihan.diskon_detil" :key="diskon.bayar_diskon_keterangan + '_' + index">
                            <label class="col-7 col-form-label">{{diskon.bayar_diskon_keterangan}}</label>
                            <label class="col-5 col-form-label text-right text-value">{{rupiah_pengurang(diskon.bayar_diskon_nominal)}}</label>
                        </div>
                    </template>

                    <div class="form-group row" v-if="(diskonTemp(tagihan).length)">
                        <label class="col-12 col-form-label pb-0"><small>Promo</small></label>
                    </div>
                    <div class="form-group row mb-0" v-for="(diskon, index) in diskonTemp(tagihan)" :key="diskon.bayar_diskon_keterangan + '_' + index">
                        <label class="col-7 col-form-label">{{diskon.bayar_diskon_keterangan}}</label>
                        <label class="col-5 col-form-label text-right text-value">{{rupiah_pengurang(diskon.bayar_diskon_nominal)}}</label>
                    </div>
                    
                    <template v-if="tagihan.ui_props.show_cicilan == true">
                        <div class="form-group row" v-if="(tagihan.cicilan_detil.length)">
                            <label class="col-12 col-form-label pb-0"><small>Nominal Terbayar</small></label>
                        </div>
                        <div class="form-group row mb-0" v-for="(cicilan, index) in tagihan.cicilan_detil" :key="cicilan.bayar_cicil_tanggal + '_' + index">
                            <label class="col-7 col-form-label">{{formatDDMMYYYY(cicilan.bayar_cicil_tanggal)}}</label>
                            <label class="col-5 col-form-label text-right text-value">{{rupiah_pengurang(cicilan.bayar_cicil_nominal)}}</label>
                        </div>
                    </template>
                </div>
                <div class="card-footer">
                    <div class="form-group row mb-0">
                        <label class="col-7 col-form-label">Nominal Wajib Bayar</label>
                        <label class="col-5 col-form-label text-right"><strong>{{rupiah(tagihan.nominal_kurang_bayar)}}</strong></label>
                    </div>

                </div>
            </div>

            <div class="card" style="border: 1px solid #aaabad;" v-for="tagihan in tagihanInsidentil" :key="tagihan.bayar_siswa_id">
                <div class="card-header">
                    <checkbox :checkbox="tagihan"></checkbox>
                    <small style="padding-left: 20px; font-weight: bold;" v-if="tagihan.kewajiban_keterangan">( {{tagihan.kewajiban_keterangan}} )</small>
                </div>
                <div class="card-body">
                    <div class="form-group row mb-0">
                        <label class="col-7 col-form-label">Nominal Tagihan</label>
                        <label class="col-5 col-form-label text-right">{{rupiah_pokok(tagihan.bayar_siswa_nominal)}}</label>
                    </div>
                    <template v-if="tagihan.ui_props.show_diskon == true">
                        <div class="form-group row" v-if="(tagihan.diskon_detil.length)">
                            <label class="col-12 col-form-label pb-0"><small>Diskon</small></label>
                        </div>
                        <div class="form-group row mb-0" v-for="(diskon, index) in tagihan.diskon_detil" :key="diskon.bayar_diskon_keterangan + '_' + index">
                            <label class="col-7 col-form-label">{{diskon.bayar_diskon_keterangan}}</label>
                            <label class="col-5 col-form-label text-right text-value">{{rupiah_pengurang(diskon.bayar_diskon_nominal)}}</label>
                        </div>
                    </template>
                    <template v-if="tagihan.ui_props.show_cicilan == true">
                        <div class="form-group row" v-if="(tagihan.cicilan_detil.length)">
                            <label class="col-12 col-form-label pb-0"><small>Nominal Terbayar</small></label>
                        </div>
                        <div class="form-group row mb-0" v-for="(cicilan, index) in tagihan.cicilan_detil" :key="cicilan.bayar_cicil_tanggal + '_' + index">
                            <label class="col-7 col-form-label">{{formatDDMMYYYY(cicilan.bayar_cicil_tanggal)}}</label>
                            <label class="col-5 col-form-label text-right text-value">{{rupiah_pengurang(cicilan.bayar_cicil_nominal)}}</label>
                        </div>
                    </template>
                </div>
                <div class="card-footer">
                    <div class="form-group row mb-0">
                        <label class="col-7 col-form-label">Nominal Wajib Bayar</label>
                        <label class="col-5 col-form-label text-right"><strong>{{rupiah(tagihan.nominal_kurang_bayar)}}</strong></label>
                    </div>

                </div>
            </div>
        </div>
        <b-modal v-model="modalShow" title="Promo" @ok="applyPromo()" :ok-disabled="(!promo_selected_temp)" ok-variant="success">
            <div class="col-12" v-for="promo in promos" :key="promo.promo_id">
                <div class="card card-promo" :class="{'card-active' : (promo.promo_id == promo_selected_temp)}" @click="selectPromo(promo)">
                    <div class="card-body fw-bold">
                        <span>{{promo.promo_nama}}</span> <br>
                        <span class="text-muted mt-2 fs-small">{{ $root.humanDate(promo.promo_start_date, {withTime: false}) }} 00:00:00 &nbsp;&nbsp;s.d.&nbsp;&nbsp; {{ $root.humanDate(promo.promo_end_date, {withTime: false}) }} 23:59:59</span>
                    </div>
                </div>
            </div>

            <template #modal-ok>
                Gunakan
            </template>
            <template #modal-cancel>
                Batal
            </template>
        </b-modal>
    </div>
    
    <!-- end row -->
</template>
<script>
import {mapGetters, mapState} from 'vuex'

import Checkbox from './checkbox';

export default {
    name : 'kewajiban',
    props : [],
    data() {
        return {
            modalShow : false,
            promo_selected_temp : ""
        }
    },
    components : {
        Checkbox,
    },
    computed : {
        ...mapGetters({
            dataSiswa : 'getDataSiswa',
            tagihanBulanan : 'getTagihanBulanan',
            tagihanInsidentil : 'getTagihanInsidentil',
            allTagihan : 'getAllTagihan',
            uiConfig : 'getUiConfig',
            detailPromoSelected : 'getDetailPromo'
        }),
        ...mapState({
            promos : state => state.promos,
            promo_selected : state => state.promo_selected
        }),
        detail_promo_selected() {
            if(this.promos.length == 0) return {};
            return this.promos.filter((promo) => promo.promo_id == this.promo_selected_temp)?.[0] ?? {};
        },
        no_wa : {
            get() {
                return this.$store.state.no_wa
            },
            set(value){
                this.$store.commit('setNoWa', value)
            }
        },
        email : {
            get() {
                return this.$store.state.email
            },
            set(value){
                this.$store.commit('setEmail', value)
            }
        },
        tagihan_checked : {
            get() {
                return this.$store.state.tagihan_checked;
            }
        }
    },

    methods : {
        
        rupiah_pokok : (number) => new Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR" }).format(number).replace("Rp", '').replace(",00", ''),
        rupiah_pengurang : (number) => new Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR" }).format(number).replace("Rp", '-').replace(",00", ''),
        rupiah : (number) => new Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR" }).format(number).replace(",00", ''),
        isNumber: function(evt) {
            evt = (evt) ? evt : window.event;
            let charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        formatDDMMYYYY: (date) => date.split("-").reverse().join("-"),
        openModalPromo() {
            this.modalShow = true;
        },
        selectPromo({promo_id}) {
            this.promo_selected_temp = promo_id;
        },
        clearPromo() {
            this.$emit('cancelPromo', this.promo_selected);
            this.promo_selected_temp = '';
        },
        applyPromo() {
            this.$emit('applyPromo', this.promo_selected_temp);
        },
        diskonTemp(tagihan) {
            return tagihan?.diskon_temporary.filter(f => f.id_promo == this.promo_selected) ?? [];
        }
    },
}
</script>

<style scoped>
    .title-kewajiban{
        min-height: 50px;
        background: #e7e5fc;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        border-radius: 0.70rem;
    }

    .alert-success {
        border-radius: 0.70rem;
    }

    .body-kewajiban, .card-kewajiban{
        display: flex;
        flex-direction: column;
    }

    .text-right{
        text-align: right;
    }

    .body-kewajiban .card-body{
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
    }

    .text-value {
        display: flex;
        justify-content: right;
        align-items: center;
    }

    .form-control {
        border-radius: 0.50rem;
    }

    .card {
        margin-bottom: 18px;
    }

    .card-promo {
        border : 1px solid #d6f3e9;
    }

    .card-active {
        background: #d6f3e9;
        color: #1f7556;
        font-weight: bold;
        transition: 0.2s;
    }

    .fs-small {
        font-size: 0.6rem !important;
    }
</style>