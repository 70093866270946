<script>
    import { FormWizard, TabContent, WizardButton, WizardStep } from "vue-form-wizard";
    import appConfig from "@/app.config";
    import Kewajiban from "./payment/kewajiban"
    import ChannelBayar from "./payment/channel-bayar"
    import Konfirm from "./payment/konfirm"
    import Final from "./payment/final"
    import Axios from "axios";

    import {mapGetters} from 'vuex'

    const urlNodeJs = process.env.VUE_APP_URL_NODE;
    const paymentApiKey = process.env.VUE_APP_SS_PAYMENT_API_KEY;

    const headersPaymentApi = {
        headers : {
            'Content-Type' : 'application/json',
            'X-API-KEY' : paymentApiKey
        },
        crossDomain : true
    }

    /**
     * Form wizard component
     */
    export default {
        page: {
            title: "Bayar",
            meta: [{
                name: "description",
                content: appConfig.description
            }],
        },
        components: { FormWizard, TabContent, WizardButton, WizardStep, Kewajiban, ChannelBayar, Konfirm, Final},
        data() {
            return {
                buttonText : ['Pilih Channel Pembayaran', 'Lanjutkan', 'Bayar Sekarang'],
                isLoading : false,
                timer : 0,
                intervalTimer : ""
                // title: "Form Wizard",
                // items: [{
                //         text: "Forms",
                //         href: "/",
                //     },{
                //         text: "Form Wizard",
                //         active: true,
                //     },
                // ],
            };
        },

        methods : {
            
            getImgUrl(pet) {
                let images = require.context('@/assets/images/channel-bayar/', false, /\.png$/)
                return images('./' + pet)
            },
            number_phone_validation(number) {
                let isnum = /^\d+$/.test(number);
                if(!isnum && number != ""){
                    this.$swal({
                        icon : 'warning',
                        title : '',
                        text : 'Tolong inputkan nomer dengan benar'
                    });
                    return false;
                }
                let checkfirstdigit = /^62.*|^0.*/.test(number);
                if(!checkfirstdigit && number != ""){
                    this.$swal({
                        icon : 'warning',
                        title : '',
                        text : 'Tolong inputkan nomer dengan 0 Atau 62 di awalan'
                    });
                    return false;
                }
                return true;
            },
            validateStepOne(){
                // this.$refs.form_wizard.changeTab(0, 3);
                const tagihan_checked = this.$store.getters.getTagihanChecked;
                if(tagihan_checked.length <= 0){
                    this.$swal({
                        icon : 'warning',
                        title : '',
                        text : 'Kamu belum melakukan centang pada kewajiban yang ingin dibayar'
                    });
                    return false;
                }
                
                // var emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
                // const email = this.$store.getters.getEmail ?? "";
                // if (!email || !email.trim().match(emailRegex)) {
                //     alert("Invalid Email Address");
                //     return false;
                // }

                let no_hp = this.$store.getters.getNoWa ?? "";
                no_hp = no_hp.trim();
                if(!this.number_phone_validation(no_hp)) return false;

                // Check List Channel
                const checkListChannel = this.data_channel.filter((c) => this.listAvailableChannel.indexOf(c.value) > -1);
                if(checkListChannel.length <= 0){
                    this.$swal({
                        icon : 'warning',
                        title : '',
                        text : 'Channel pembayaran tidak ditemukan, coba lagi di lain waktu'
                    });
                    return false;
                }
                // End Check List Channel
                
                this.$store.commit("setChannelSelected", "");
                window.scrollTo(0, 0);
                return true;
            },
            async validateStepTwo() {
                const channelSelected = this.$store.getters.getChannelSelected;
                
                if(!channelSelected || channelSelected == ""){
                    this.$swal({
                        icon : 'warning',
                        title : '',
                        text : 'Kamu belum memilih channel pembayaran'
                    });
                    return false;
                }
                window.scrollTo(0, 0);
                return true;
            },
            async validateStepThree() {
                const channelSelectedDetail = this.$store.getters.getChannelSelectedDetail;
                const phone_required = channelSelectedDetail?.phone_required ?? false;

                let no_hp = this.$store.getters.getNoWallet ?? "";
                no_hp = no_hp.trim();
                
                let label_phone = channelSelectedDetail?.label_phone ?? channelSelectedDetail.channel_name;
                if(phone_required ){
                    if(!no_hp || no_hp == ""){
                        this.$swal({
                            icon : 'warning',
                            title : '',
                            text : `Nomor ${label_phone} wajib diisi`
                        });
                        return false;
                    }
                    if(!this.number_phone_validation(no_hp)) return false;
                }

                const confirmAction = await this.$swal({
                    title: 'Setelah ini anda akan diarahkan ke panduan tata cara pembayaran dan tidak dapat kembali ke step sebelumnya sebelum terbayar lunas.<br/><br/>Anda yakin?',
                    showDenyButton: true,
                    confirmButtonText: 'Yes',
                    denyButtonText: `No`,
                    allowOutsideClick: false
                })
                if(confirmAction.isDenied == true){
                    return false;
                }
                // window.console.log(confirmAction);
                if(parseInt(this.totalAdminSim) < 0){
                    this.$swal({
                        icon : 'warning',
                        title : '',
                        text : `Something Wrong Internal`
                    });
                    return false;
                }
                
                if(parseInt(this.totalTagihan) <= 0){
                    this.$swal({
                        icon : 'warning',
                        title : '',
                        text : `Minimum Amount Billing > 0`
                    });
                    return false;
                }
                
                this.isLoading = true;
                try {
                    
                    // Check inquiry Lagi Untuk Proses Sebelum Requests Payment                
                    const dataInquiry = await this.getInquiryBilling(false);

                    // Check Tagihan Inquiry Dengan Tagihan Yang Sudah Ter Check
                    const allTagihanInquiry = [...dataInquiry.data_tagihan.bulanan, ...dataInquiry.data_tagihan.insidentil];
                    
                    const tagihanCheckedDetailNow = this.$store.getters.getDetailTagihanChecked;

                    const validasiTagihanInquiry = allTagihanInquiry.filter((t) => {
                        const arr_tagihan_checked = tagihanCheckedDetailNow.filter((v) => v.bayar_siswa_id == t.bayar_siswa_id && v.nominal_kurang_bayar == t.nominal_kurang_bayar);

                        return arr_tagihan_checked.length > 0;
                    })

                    if(validasiTagihanInquiry.length != tagihanCheckedDetailNow.length){
                        await this.$swal({
                            icon : 'warning',
                            title : '',
                            text : 'Sistem mendeteksi adanya perubahan dalam daftar tagihan',
                            confirmButtonText: 'Reload Page',
                            allowOutsideClick: false
                        });
                        this.isLoading = false;
                        this.$router.go(0)
                        // this.$router.push({name : 'payment', query : {appRoute : this.$route.query.appRoute}});
                        return false;
                    }
                    // End Check Tagihan Inquiry Dengan Tagihan Yang Sudah Ter Check

                    // Check Channel Masih Tersedia Atau Tidak
                    const listChannel = dataInquiry.list_channel;
                    const channelSelected = this.$store.getters.getChannelSelected;

                    if(listChannel.indexOf(channelSelected) == "-1"){
                        await this.$swal({
                            icon : 'warning',
                            title : '',
                            text : 'Channel pembayaran yang anda pilih tidak tersedia, coba channel lain',
                            confirmButtonText: 'Reload Page',
                            allowOutsideClick: false
                        });
                        this.isLoading = false;
                        this.$router.go(0)
                        return false;
                    }
                    // End Check Channel Masih Tersedia Atau Tidak

                    const requestedIdBilling = dataInquiry?.requested_id_billing?.id_billing ?? null;

                    let checkNav;
                    if(requestedIdBilling) checkNav = await this.checkStatusBilling(requestedIdBilling);
                    if(checkNav) return false;
                } catch (err) {
                    window.console.log(err);
                    this.isLoading = false;
                    const status_code = err?.response?.status ?? 500;
                    if(status_code == 401){
                        await this.$swal({
                            icon : 'warning',
                            title : '',
                            text : err?.response?.data?.message ?? 'Sesi Telah Berakhir',
                            confirmButtonText: 'Reload Page',
                            allowOutsideClick: false
                        });
                        return this.$router.go(0)
                    }
                    this.$swal({
                        icon : 'warning',
                        title : '',
                        text : 'Terjadi kesalahan pada sistem channel pembayaran yang anda pilih, coba lagi nanti atau pilih channel pembayaran lain'
                    });
                    return false;
                }

                try{
                    
                    let saveBillToken, saveBillRequest;

                    // Start Get Token From Save Bill
                    saveBillToken = await Axios.post(urlNodeJs + "/token", {
                        data_request : {
                            dep_kode : this.$route.query.dep_kode,
                            id_siswa : Buffer.from(this.$route.query.id_siswa).toString("base64"),
                            checked_id_bayar : this.$store.getters.getTagihanChecked,
                            id_promo : this.$store.state.promo_selected ?? null
                        },
                        headers_request : {
                            'Content-Type' : 'application/json',
                        },
                    }, headersPaymentApi);
                    
                    const saveBillTokenSecretData = saveBillToken?.data?.secretData ?? null;
                    if(!saveBillTokenSecretData) throw new Error("Secret Data Missing");

                    // Save Bill Request
                    saveBillRequest = await Axios.post(urlNodeJs + "/sim_request/save_billing", {payload : saveBillTokenSecretData}, headersPaymentApi);
                    // window.console.log(saveBillRequest);

                    const dataBilling = saveBillRequest?.data?.data ?? null;

                    if(dataBilling){
                        this.$store.commit("setSaveBillData", dataBilling);
                        
                        const no_wa = this.$store.getters.getNoWa ?? '';
                        const no_wallet = this.$store.getters.getNoWallet ?? '';

                        const dataSend = {
                            channel : this.$store.getters.getChannelSelected,
                            dep_kode : this.$route.query.dep_kode,
                            data_billing : this.$store.getters.getSaveBillData,
                            data_siswa : this.$store.getters.getDataSiswa,
                            fee_admin_sim : parseInt(this.totalAdminSim),
                            fee_admin : parseInt(this.totalAdmin),
                            data_tagihan : {bulanan : this.$store.getters.getTagihanBulanan, insidentil : this.$store.getters.getTagihanInsidentil},
                            no_wa : no_wa.trim(),
                            no_wallet : no_wallet.trim(),
                            email : this.$store.getters.getEmail,
                            promo : {...this.detailPromoSelected, amount : this.totalPromo} ?? null
                        }

                        let saveRequestToken, saveRequestResponse;
                        //  Get Token For Request Payment
                        saveRequestToken = await Axios.post(urlNodeJs + "/token", dataSend, headersPaymentApi);

                        const saveRequestSecretData = saveRequestToken?.data?.secretData ?? null;
                        if(!saveRequestSecretData) throw new Error("Secret Data Missing");

                        // Request Payment to Backend Node JS
                        saveRequestResponse = await Axios.post(urlNodeJs + "/payment/api/request_payment", {payload : saveRequestSecretData}, headersPaymentApi);
                        
                        const dataRequest = saveRequestResponse?.data ?? null;
                        
                        if(dataRequest && saveRequestResponse.status == "200" && !dataRequest.hasOwnProperty("status_code")) {
                            this.$store.commit("setPaymentInfo", dataRequest.payment_info);
                            this.$store.commit("setDateExpired", dataRequest.dateExpired);
                            this.$store.commit("setIdBilling", dataRequest.id_billing);
                            this.$store.commit("setFeeAdminIdBilling", dataRequest.fee_admin);
                            
                            this.isLoading = false;
                            // window.console.log(saveRequestResponse);
                            window.scrollTo(0,0);
                            return true;
                        } else {
                            this.isLoading = false;
                            // window.console.log(saveRequestResponse);
                            this.$swal({
                                icon : 'warning',
                                title : '',
                                text : 'Tidak bisa request kode bayar, hubungi operator sekolah'
                            });
                        }
                    }

                    return false
                } catch (err) {
                    window.console.log(err);
                    this.isLoading = false;

                    const status_code = err?.response?.status ?? 500;
                    if(status_code == 401){
                        await this.$swal({
                            icon : 'warning',
                            title : '',
                            text : err?.response?.data?.message ?? 'Sesi Telah Berakhir',
                            confirmButtonText: 'Reload Page',
                            allowOutsideClick: false
                        });
                        return this.$router.go(0)
                    }
                    this.$swal({
                        icon : 'warning',
                        title : '',
                        text : 'Terjadi kesalahan pada sistem channel pembayaran yang anda pilih, coba lagi nanti atau pilih channel pembayaran lain'
                    });
                    return false;
                }

            },
            async validateStepFour() {
                this.isLoading = true;

                try{
                    let checkRequestIdBillingToken, checkRequestIdBillingResponse;
    
                    const id_billing = this.$store.getters.getIdBilling;
                    checkRequestIdBillingToken = await Axios.post(urlNodeJs + "/token", {id_billing : id_billing}, headersPaymentApi);

                    const requestIdBillingTokenSecretData = checkRequestIdBillingToken?.data?.secretData ?? null;
                    if(!requestIdBillingTokenSecretData) throw new Error("Secret Data Missing");
 
                    checkRequestIdBillingResponse = await Axios.post(urlNodeJs + "/payment/api/check_id_billing", {payload : requestIdBillingTokenSecretData}, headersPaymentApi);
                    
                    const requestedIdBillingStatus = checkRequestIdBillingResponse?.data?.transaction_status ?? null;
                    if(requestedIdBillingStatus){
                        
                        if(requestedIdBillingStatus == "settlement"){
                            this.$store.commit("setBillingExists", checkRequestIdBillingResponse.data);
                            this.$router.push({name : 'payment_success', query : {appRoute : this.$route.query.appRoute}});
                            
                        } else if(requestedIdBillingStatus == "expire"){
                            this.$store.commit("setBillingExists", checkRequestIdBillingResponse.data);
                            this.$router.push({name : 'expired', query : {appRoute : this.$route.query.appRoute}});

                        }
                        this.isLoading = false;
                        this.timer = 15;
                        return true;
                    }
                } catch (err) {
                    window.console.log(err);
                    this.isLoading = false;

                    const status_code = err?.response?.status ?? 500;
                    if(status_code == 401){
                        await this.$swal({
                            icon : 'warning',
                            title : '',
                            text : 'Sesi Telah Berakhir',
                            confirmButtonText: 'Reload Page',
                            allowOutsideClick: false
                        });
                        return this.$router.go(0)
                    }
                    return false;
                }


            },
            goBack() {
                window.scrollTo(0,0);
                this.$refs.form_wizard.prevTab()
            },
            resetData() {
                this.$store.commit("setDateExpired", "");
                this.$store.commit("setIdBilling", "");
                this.$store.commit("setChannelSelected", "");
                this.$store.commit("setFeeAdminIdBilling", "");
                this.$store.commit("setNoWallet", "");
                this.$store.commit("setPaymentInfo", {});
                this.$store.commit("setTagihanChecked", []);
                this.$store.commit("setPromoSelected", '');
                this.$store.commit("setTagihanLocked", []);

                this.timer = 0;
                this.intervalTimer = "";
            },

            async getVendorConfig(){
                let getVendorConfigToken = await Axios.post(urlNodeJs + "/token", {dep_kode : this.$route.query.dep_kode}, headersPaymentApi);

                const vendorConfigSecretData = getVendorConfigToken?.data?.secretData ?? null;
                if(!vendorConfigSecretData) throw new Error("Secret Data Missing");

                let vendorConfigResponse = await Axios.post(urlNodeJs + "/api/vendor_config", {payload : vendorConfigSecretData}, headersPaymentApi);

                const vendorConfig = vendorConfigResponse?.data?.data ?? {};
                
                if(vendorConfig){
                    this.$store.commit('setVendorConfig', vendorConfig);
                }
            },
            async getInquiryBilling(store = true){
                let responseBillingToken, responseBillingInquiry;
                // Token Inquiry
                responseBillingToken = await Axios.post(urlNodeJs + "/token", {
                    data_request : {
                        dep_kode : this.$route.query.dep_kode,
                        id_siswa : Buffer.from(this.$route.query.id_siswa).toString("base64"),
                    },
                    headers_request : {
                        'Content-Type' : 'application/json',
                    },
                }, headersPaymentApi);

                const billingTokenSecretData = responseBillingToken?.data?.secretData ?? null;
                if(!billingTokenSecretData) throw new Error("Secret Data Missing");

                responseBillingInquiry = await Axios.post(urlNodeJs + "/sim_request/inquiry", {payload : billingTokenSecretData}, headersPaymentApi);

                const dataInquiry = responseBillingInquiry?.data?.data ?? null;
                if(!dataInquiry) throw new Error("Data Inquiry Missing");

                // Jika Maintenance
                if(dataInquiry.dep_maintenance_payment_gateway == "1"){
                    this.$router.push({
                        name : 'maintenance', 
                        query : {
                            appRoute : this.$route.query.appRoute, 
                            message : dataInquiry.dep_maintenance_text
                        },
                    });
                    return false;
                }

                if(!store) return dataInquiry;

                this.$store.commit("setDataSiswa", dataInquiry.data_siswa);
                if(this.$store.getters.getNoWa == "") {
                    this.$store.commit('setNoWa', dataInquiry.data_siswa.siswa_hp);
                }
                this.$store.commit('setEmail', dataInquiry.data_siswa.siswa_email);

                this.$store.commit("setPromos", dataInquiry.data_promo);

                this.$store.commit("setDataTagihanBulanan", dataInquiry.data_tagihan.bulanan);
                this.$store.commit("setDataTagihanInsidentil", dataInquiry.data_tagihan.insidentil);

                this.$store.commit("setListAdminFee", dataInquiry.biaya_transaksi);
                this.$store.commit("setListAdminTipe", dataInquiry.tipe_fee);
                this.$store.commit("setListMaxAmount", dataInquiry.max_amount);
                this.$store.commit("setListAvailableChannel", dataInquiry.list_channel);
                // this.$store.commit("setUiConfig", dataInquiry.ui_config);
                this.$store.commit("setTagihanChecked", []);

                const allTagihan = this.$store.getters.getAllTagihan;
                
                // Redirect Billing Empty Jika Tidak Ada Tagihan Lagi
                if(allTagihan.length == 0){
                    return this.$router.push({name : 'billing_empty', query : {appRoute : this.$route.query.appRoute}});
                }
                const idBayar = allTagihan.filter((f) => f.ui_props.harus_lunas == true).map((t) => t.bayar_siswa_id);

                for(let key in idBayar) {
                    this.$store.commit('addToChecked', idBayar[key]);
                }
                return dataInquiry;
            },
            async getNoWaPayment() {
                // Get Nomor Wa From SS_PAYMENT_V2
                let getNomorWaToken, getNomorWaResponse;

                getNomorWaToken = await Axios.post(urlNodeJs + "/token", {id_siswa : Buffer.from(this.$route.query.id_siswa).toString('base64')}, headersPaymentApi);

                const nomorWaSecretData = getNomorWaToken?.data?.secretData ?? null;
                if(!nomorWaSecretData) throw new Error("Secret Data Missing");

                getNomorWaResponse = await Axios.post(urlNodeJs + "/get_whatsapp_number", {payload : nomorWaSecretData}, headersPaymentApi);

                const nomorWa = getNomorWaResponse?.data?.wa_number ?? null;
                
                if(nomorWa){
                    this.$store.commit('setNoWa', nomorWa);
                }
                // End Get Nomor Wa From SS_PAYMENT_V2
            },
            async checkStatusBilling(requestedIdBilling) {
                let checkRequestIdBillingToken, checkRequestIdBillingResponse;

                checkRequestIdBillingToken = await Axios.post(urlNodeJs + "/token", {id_billing : requestedIdBilling}, headersPaymentApi);
                
                const requestIdBillingTokenSecretData = checkRequestIdBillingToken?.data?.secretData ?? null;
                if(!requestIdBillingTokenSecretData) throw new Error("Secret Data Missing");

                checkRequestIdBillingResponse = await Axios.post(urlNodeJs + "/payment/api/check_id_billing", {payload : requestIdBillingTokenSecretData}, headersPaymentApi);
                
                const requestedIdBillingStatus = checkRequestIdBillingResponse?.data?.transaction_status ?? null;

                // Jika ada order_id di GET
                if(this.$route.query.order_id && requestedIdBillingStatus && ['expire', 'settlement'].indexOf(requestedIdBillingStatus) > "-1"){
                    this.$store.commit("setBillingExists", checkRequestIdBillingResponse.data);

                    let appRoute = btoa(`dep_kode=${this.$route.query.dep_kode}&id_siswa=${this.$route.query.id_siswa}`);
                    
                    if(requestedIdBillingStatus == "expire") return this.$router.push({name : 'expired', query : {appRoute}});
                    if(requestedIdBillingStatus == "settlement") return this.$router.push({name : 'payment_success', query : {appRoute}});
                }
                // Jika Terdeteksi ID Billing dengan status Pending
                if(requestedIdBillingStatus && ['pending'].indexOf(requestedIdBillingStatus) > "-1"){
                    const dataRequestedIdBilling = checkRequestIdBillingResponse?.data ?? null;

                    this.$store.commit("setDataTagihanBulanan", dataRequestedIdBilling.inquiry_data.data_tagihan.bulanan);
                    this.$store.commit("setDataTagihanInsidentil", dataRequestedIdBilling.inquiry_data.data_tagihan.insidentil);

                    this.$store.commit("setTagihanChecked", []);
                    const tagihan_checked = dataRequestedIdBilling.detil_tagihan.map((t) => t.bayar_siswa_id);
                    
                    for(let key_tagihan in tagihan_checked) {
                        this.$store.commit('addToChecked', tagihan_checked[key_tagihan]);
                    }
                    
                    this.$store.commit("setChannelSelected", dataRequestedIdBilling.inquiry_data.channel);
                    this.$store.commit("setPaymentInfo", dataRequestedIdBilling.payment_info);
                    this.$store.commit("setDateExpired", dataRequestedIdBilling.midtrans_expired_at);
                    this.$store.commit("setNoWa", dataRequestedIdBilling.inquiry_data.data_siswa.siswa_hp);
                    this.$store.commit("setEmail", dataRequestedIdBilling.inquiry_data.data_siswa.siswa_email);
                    this.$store.commit("setFeeAdminIdBilling", dataRequestedIdBilling.inquiry_data.fee_admin);

                    this.$store.commit("setIdBilling", dataRequestedIdBilling.order_id);

                    let no_wallet = dataRequestedIdBilling?.payment_info?.no_wallet ?? null;
                    if(no_wallet) this.$store.commit("setNoWallet", no_wallet);

                    this.$store.commit("setPromoSelected", dataRequestedIdBilling.inquiry_data?.promo?.promo_id ?? '');
                    this.$store.commit("setPromos", [{...dataRequestedIdBilling.inquiry_data?.promo}] ?? []);



                    this.isLoading = false;

                    this.$refs.form_wizard.activateAll();
                    return this.$refs.form_wizard.changeTab(0, 3);
                } else if(requestedIdBillingStatus && ['settlement'].indexOf(requestedIdBillingStatus) > "-1" && checkRequestIdBillingResponse.data.sim_settlement == "0") { // Jika Pembayaran Masih Progress Di Sim
                    return this.$router.push({name : 'payment_progress', query : {appRoute : this.$route.query.appRoute}});
                }
            },
            async applyPromo(id_promo) {
                try {
                    this.isLoading = true;
                    let applyPromoRequest, applyPromoResponse;
                    // Token Inquiry
                    applyPromoRequest = await Axios.post(urlNodeJs + "/token", {
                        data_request : {
                            dep_kode : this.$route.query.dep_kode,
                            id_siswa : Buffer.from(this.$route.query.id_siswa).toString("base64"),
                            id_promo
                        },
                        headers_request : {
                            'Content-Type' : 'application/json',
                        },
                    }, headersPaymentApi);

                    const applyPromoToken = applyPromoRequest?.data?.secretData ?? null;
                    if(!applyPromoToken) throw new Error("Secret Data Missing");
                    
                    applyPromoResponse = await Axios.post(urlNodeJs + "/sim_request/promo/apply", {payload : applyPromoToken}, headersPaymentApi);

                    const responsePromo = applyPromoResponse?.data?.data ?? null;
                    if(!responsePromo) throw new Error("Something Wrong!");

                    await this.getInquiryBilling();

                    const billNeedChecked = responsePromo?.bill_must_checked ?? [];
                    for(let id_bayar of billNeedChecked) {
                        if(this.$store.getters.getTagihanChecked.indexOf(id_bayar) == '-1') {
                            this.$store.commit('addToChecked', id_bayar);
                        }
                    }
                    this.$store.commit('setTagihanLocked', billNeedChecked);
                    this.$store.commit('setPromoSelected', id_promo);
                } catch (err) {
                    window.console.log(err);
                    const status_code = err?.response?.status ?? 500;
                    if(status_code == 401){
                        await this.$swal({
                            icon : 'warning',
                            title : '',
                            text : 'Sesi Telah Berakhir',
                            confirmButtonText: 'Reload Page',
                            allowOutsideClick: false
                        });
                        return this.$router.go(0)
                    } else if(status_code == 400) {
                        await this.$swal({
                            icon : 'warning',
                            title : '',
                            text : err?.response?.data?.message ?? err?.response?.data?.error ?? 'Something Wrong',
                        });
                    } else {
                        this.$router.push({name : '404', query : {appRoute : this.$route.query.appRoute}});
                    }
                    return false;
                } finally {
                    this.isLoading = false;
                }
            },

            async cancelPromo(id_promo) {
                try {
                    this.isLoading = true;
                    let cancelPromoRequest, cancelPromoResponse;
                    // Token Inquiry
                    cancelPromoRequest = await Axios.post(urlNodeJs + "/token", {
                        data_request : {
                            dep_kode : this.$route.query.dep_kode,
                            id_siswa : Buffer.from(this.$route.query.id_siswa).toString("base64"),
                            id_promo
                        },
                        headers_request : {
                            'Content-Type' : 'application/json',
                        },
                    }, headersPaymentApi);

                    const cancelPromoToken = cancelPromoRequest?.data?.secretData ?? null;
                    if(!cancelPromoToken) throw new Error("Secret Data Missing");
                    
                    cancelPromoResponse = await Axios.post(urlNodeJs + "/sim_request/promo/cancel", {payload : cancelPromoToken}, headersPaymentApi);

                    const responseCancelPromo = cancelPromoResponse?.data?.data ?? null;
                    if(!responseCancelPromo) throw new Error("Something Wrong!");

                    this.$store.commit('setTagihanLocked', []);
                    await this.getInquiryBilling();
                    this.$store.commit('setPromoSelected', '');
                } catch (err) {
                    window.console.log(err);
                    const status_code = err?.response?.status ?? 500;
                    if(status_code == 401){
                        await this.$swal({
                            icon : 'warning',
                            title : '',
                            text : 'Sesi Telah Berakhir',
                            confirmButtonText: 'Reload Page',
                            allowOutsideClick: false
                        });
                        return this.$router.go(0)
                    } else if(status_code == 400) {
                        await this.$swal({
                            icon : 'warning',
                            title : '',
                            text : err?.response?.data?.message ?? err?.response?.data?.error ?? 'Something Wrong',
                        });
                    } else {
                        this.$router.push({name : '404', query : {appRoute : this.$route.query.appRoute}});
                    }
                    return false;
                } finally {
                    this.isLoading = false;
                }
            }
        },

        async created() {
            this.resetData();
            // window.console.log(new Date('2021-12-25').getTime(), new Date('2021-12-31').getTime());
            if(!this.$route.query.hasOwnProperty('id_siswa') || !this.$route.query.hasOwnProperty('dep_kode')){
                return this.$router.push('404');
            }
            try{
                const requestAccessToken = await Axios.get(urlNodeJs + "/api/auth/access_token", {
                    headers : {
                        "X-API-KEY" : paymentApiKey,
                    }
                });

                const access_token = requestAccessToken?.data?.data?.accessToken ?? null;
                headersPaymentApi.headers['X-ACCESS-TOKEN'] = access_token;
                
                this.isLoading = true;

                await this.getVendorConfig();
                const dataInquiry = await this.getInquiryBilling();
                await this.getNoWaPayment();

                let requestedIdBilling = dataInquiry?.requested_id_billing?.id_billing ?? null;
                if(this.$route.query.order_id) requestedIdBilling = this.$route.query.order_id // Jika ada order_id di GET
                
                if(requestedIdBilling) await this.checkStatusBilling(requestedIdBilling);
                
                this.isLoading = false
                // window.console.log(responseBillingToken, responseBillingInquiry);
            } catch(err) {
                window.console.log(err);
                this.isLoading = false;
                const status_code = err?.response?.status ?? 500;
                if(status_code == 401){
                    await this.$swal({
                        icon : 'warning',
                        title : '',
                        text : 'Sesi Telah Berakhir',
                        confirmButtonText: 'Reload Page',
                        allowOutsideClick: false
                    });
                    return this.$router.go(0)
                } else {
                    this.$router.push({name : '404', query : {appRoute : this.$route.query.appRoute}});
                }
                return false;
            }
        },

        computed : {
            ...mapGetters({
                data_channel : 'getChannel',
                listAvailableChannel : 'getListAvailableChannel',
                ChannelSelectedFromStore : 'getChannelSelectedDetail',
                listAdminFee : 'getListAdminFee',
                getDetailTagihanChecked : 'getDetailTagihanChecked',
                totalNominalTagihan : 'getTotTagihan',
                totalAdmin : 'getTotAdmin',
                totalTagihanWithAdmin : 'getTotTagihanWithAdmin',
                totalAdminSim : 'getAdminSS',
                detailPromoSelected : 'getDetailPromo',
                totalPromo : 'getPromoAmount',
                totalTagihan : 'getTotTagihan',

            }),
        },
        watch : {
            timer(val) {
                if(val > 0 && this.intervalTimer == ""){
                    this.isLoading = true;
                    this.intervalTimer = setInterval(() => {
                        this.timer--;
                    }, 1000)
                } else {
                    if(val <= 0){
                        clearInterval(this.intervalTimer);
                        this.isLoading = false;
                        this.intervalTimer = "";
                    }
                }
            }
        }
    };
</script>

<template>
    <div class="container container_shadow">
        <div class="card" style="background-color: #f8f8fb; margin-bottom: 0px;">
            <div class="card-body" style="min-height: 100vh">
                <!-- <h4 class="card-title">Basic Wizard</h4> -->
                <form-wizard ref="form_wizard" color="#556ee6" finish-button-text="Oke, Sudah Saya Bayar">
                    <wizard-step slot-scope="props" slot="step" :tab="props.tab" :transition="props.transition" :index="props.index">
                        <div slot="active-step" style="display: flex; justify-content:center; align-items: center;">
                            <img src="@/assets/images/step-logo/checkboxes.png" alt="checkbox" v-if="props.index == 0" :height="45" :width="45">
                            <img src="@/assets/images/step-logo/credit.png" alt="checkbox" v-if="props.index == 1" :height="45" :width="45">
                            <img src="@/assets/images/step-logo/credit-card.png" alt="checkbox" v-if="props.index == 2" :height="45" :width="45">
                            <img src="@/assets/images/step-logo/pay-click.png" alt="checkbox" v-if="props.index == 3" :height="45" :width="45">

                        </div>
                        <div style="display: flex; justify-content:center; align-items: center;">
                            <img src="@/assets/images/step-logo/checkboxes_BW.png" alt="checkbox" v-if="props.index == 0" :height="45" :width="45">
                            <img src="@/assets/images/step-logo/credit_BW.png" alt="checkbox" v-if="props.index == 1" :height="45" :width="45">
                            <img src="@/assets/images/step-logo/credit-card_BW.png" alt="checkbox" v-if="props.index == 2" :height="45" :width="45">
                            <img src="@/assets/images/step-logo/pay-click_BW.png" alt="checkbox" v-if="props.index == 3" :height="45" :width="45">

                        </div>
                    </wizard-step>
                    <tab-content icon="" :before-change="() => validateStepOne()">
                        <kewajiban ref="kewajiban" @applyPromo="applyPromo" @cancelPromo="cancelPromo"></kewajiban>
                    </tab-content>
                    <tab-content icon="" :before-change="() => validateStepTwo()">
                        <channel-bayar></channel-bayar>
                        <!-- end row -->
                    </tab-content>
                    <tab-content icon="" :before-change="() => validateStepThree()">
                        <konfirm></konfirm>
                    </tab-content>
                    <tab-content icon="" :before-change="() => validateStepFour()">
                        <final></final>
                    </tab-content>

                    <template slot="footer" scope="props">
                        <div class="wizard-footer-left">
                        <!-- remove previous button -->
                        <wizard-button class="btn btn-outline-primary" v-if="props.activeTabIndex > 0 && !props.isLastStep" @click.native="goBack()" :style="{'background-color': 'transparent', 'color': '#556ee6', 'border-color': '#556ee6'}" :disabled="isLoading">Kembali</wizard-button>
                        </div>
                        <div class="wizard-footer-right" :style="{'width' : (props.isLastStep) ? '100%' : 'auto'}">
                        
                        <wizard-button @click.native="props.nextTab()" class="wizard-footer-right finish-button" :style="props.fillButtonStyle" :disabled="isLoading">
                            <template v-if="isLoading">
                                <i v-if="timer <= 0" class="fa fa-spinner fa-spin"></i>
                                <span v-else>{{timer}}</span>
                            </template>
                            <template v-else>
                                <i class="fa fa-arrow-alt-circle-right" v-if="!props.isLastStep" style="padding-right: 0.5rem"></i>
                                {{props.isLastStep ? 'Oke, Sudah Saya Bayar' : buttonText[props.activeTabIndex]}}
                            </template>
                        </wizard-button>
                        </div>
                    </template>
                </form-wizard>
            </div>
            <!-- end card-body -->
        </div>
    </div>
    <!-- end row -->
</template>

<style>
.wizard-tab-content{
    
    padding: 30px 0px 0px !important;
}

.wizard-card-footer{
    padding: 0 0px !important;
}

.btn-outline-primary{
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
}
/* .btn-outline-primary:hover, .btn-outline-primary:active{
    color: #fff !important;
    background-color: #556ee6 !important;
} */

.finish-button{
    width: 100% !important;
}

.swal2-title {
    font-size: 15px!important;
}

.card {
    border-radius: 0.70rem!important;
}

.card-bg-blue {
    background-color: #e7e5fc;
}

.card-bg-green {
    background-color: #4B8673;
    color: #F6FBF4;
}

.card.card-bg-green {
    border : 1px solid #4B8673;
}

.vue-form-wizard .wizard-btn {
    border-radius: 0.60rem!important;
}

.swal2-popup {
    border-radius: 0.75rem!important;
}

.swal2-styled.swal2-confirm, .swal2-styled.swal2-deny {
    border-radius: 0.75rem!important;
}

.text-strike {
    text-decoration: line-through;
}
</style>