<template>
    <div class="row">
        <div class="col-12 text-center mb-3" style="font-weight: bold;">Konfirmasi Data</div>
        <div class="col-12">
            <div class="card">
                <div class="card-body custom-padding">
                    <div class="form-group row mb-0">
                        <label class="col-5 col-form-label">NIS</label>
                        <label class="col-7 col-form-label text-right">{{dataSiswa.siswa_nis}}</label>
                    </div>
                    <div class="form-group row mb-0">
                        <label class="col-5 col-form-label">Nama</label>
                        <label class="col-7 col-form-label text-right">{{dataSiswa.siswa_nama}}</label>
                    </div>
                    <div class="form-group row mb-0">
                        <label class="col-5 col-form-label">No. Whatsapp</label>
                        <label class="col-7 col-form-label text-right">{{no_wa}}</label>
                    </div>
                    <!-- <div class="form-group row mb-0">
                        <label class="col-5 col-form-label">Email</label>
                        <label class="col-7 col-form-label text-right">{{email}}</label>
                    </div> -->
                </div>
            </div>
        </div>
        
        <div class="col-12 text-center mb-3" style="font-weight: bold;">Kewajiban Yang Akan Dibayar</div>
        <div class="col-12 mb-0">
            <div class="card" :class="{'card-bg-green' : (diskonTemp(tagihan).length > 0)}" style="margin-bottom: 0.75rem;" v-for="tagihan in getDetailTagihanChecked" :key="tagihan.bayar_siswa_id">
                <div class="card-body custom-padding">
                    <div class="form-group row align-items-center mb-0">
                        <label class="col-7 col-form-label">{{tagihan.wajib_nama}}</label>
                        <label class="col-5 col-form-label text-right">
                            <span :class="{'text-strike' : isStrike(tagihan)}">{{rupiah(tagihan.nominal_kurang_bayar)}}</span>
                            <span v-if="tagihan.kewajiban_keterangan"><br><small>({{tagihan.kewajiban_keterangan}})</small></span>
                        </label>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="col-12 mb-0">
            <div class="card" style="margin-bottom: 0.75rem;">
                <div class="card-body custom-padding">
                    <div class="form-group row mb-0">
                        <label class="col-4 col-form-label">Channel</label>
                        <label class="col-8 col-form-label text-right">{{ChannelSelectedFromStore.channel_name}}</label>
                    </div>
                    <div class="form-group row mb-0">
                        <label class="col-5 col-form-label">Fee Channel</label>
                        <label class="col-7 col-form-label text-right">{{(totalAdmin > 0) ? rupiah(totalAdmin) : 'GRATIS'}}</label>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-12 mb-0" v-show="promo_selected">
            <div class="card card-bg-green mb-3">
                <div class="card-body custom-padding">
                    <div class="form-group row mb-0">
                        <label class="col-7 col-form-label"><strong>{{detailPromoSelected.promo_nama}}</strong></label>
                        <label class="col-5 col-form-label text-right"><strong>- {{rupiah(promoAmount)}}</strong></label>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-12 mb-0">
            <div class="card card-bg-blue mb-3">
                <div class="card-body custom-padding">
                    <div class="form-group row mb-0">
                        <label class="col-7 col-form-label"><strong>Total</strong></label>
                        <label class="col-5 col-form-label text-right"><strong>{{rupiah(totalTagihanWithAdmin)}}</strong></label>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-12 mb-0" v-if="phoneRequired">
            <div class="card card-bg-blue">
                <div class="card-body custom-padding">
                    <div class="form-group row mb-0">
                        <label class="col-5 col-form-label" for="no_wallet">No {{labelPhone}}</label>
                        <div class="col-7">
                            <div class="input-group">
                                <input id="no_wallet" type="text" class="form-control" name="no_wallet"
                                    v-model="no_wallet" @keypress="isNumber($event)" autocomplete="off" />
                                <div class="input-group-prepend" role="button" @click="copyNumber();" title="Copy From No Whatsapp" v-if="no_wa && no_wa != ''">
                                    <span class="input-group-text" style="height: 100%;"><i class="fa fa-copy" style="height: 100%;"></i></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- end row -->
</template>
<script>
import {mapGetters, mapState} from 'vuex'

export default {
    name : 'konfirm',
    props : [],
    data() {
        return {
            path: '../../../'
        }
    },

    methods : {
        getImgUrl(pet) {
            let images = require.context('@/assets/images/channel-bayar/', false, /\.png$/)
            return images('./' + pet)
        },
        rupiah : (number) => new Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR" }).format(number ?? 0).replace(",00", ''),
        isNumber: function(evt) {
            evt = (evt) ? evt : window.event;
            let charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        copyNumber() {
            if(this.no_wa.trim()){
                this.$store.commit("setNoWallet", this.no_wa);
            }
        },
        diskonTemp(tagihan) {
            return tagihan?.diskon_temporary.filter(f => f.id_promo == this.promo_selected) ?? [];
        },
        isStrike(tagihan) {
            let totPromo = this.diskonTemp(tagihan).map((m) => m.bayar_diskon_nominal).reduce((acc, prev) => parseInt(acc) + parseInt(prev), 0);

            return (parseFloat(tagihan.nominal_kurang_bayar) === parseFloat(totPromo));
        }
    },

    computed : {
        ...mapGetters({
            ChannelSelectedFromStore : 'getChannelSelectedDetail',
            no_wa : 'getNoWa',
            email : 'getEmail',
            dataSiswa : 'getDataSiswa',
            listAdminFee : 'getListAdminFee',
            listAvailableChannel : 'getListAvailableChannel',
            getDetailTagihanChecked : 'getDetailTagihanChecked',
            totalNominalTagihan : 'getTotTagihan',
            totalAdmin : 'getTotAdmin',
            totalTagihanWithAdmin : 'getTotTagihanWithAdmin',
            detailPromoSelected : 'getDetailPromo',
            promoAmount : 'getPromoAmount'
        }),
        ...mapState({
            promo_selected : (state) => state.promo_selected
        }),
        no_wallet : {
            get() {
                return this.$store.state.no_wallet
            },
            set(value){
                this.$store.commit('setNoWallet', value)
            }
        },
        phoneRequired() {
            return (this.ChannelSelectedFromStore?.phone_required === true);
        },
        labelPhone() {
            return (this.ChannelSelectedFromStore?.label_phone) ? this.ChannelSelectedFromStore.label_phone : this.ChannelSelectedFromStore.channel_name;
        },
    }
}
</script>

<style scoped>
    .custom-padding {
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
    }

    .title-kewajiban{
        min-height: 50px;
        background: #b6b8b6;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #000;
        font-weight: bold;
        margin: 15px 0;
    }

    .card-channel{
        display: flex;
        flex-direction: column;
    }

    .channel-name{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-right: 2rem;
    }

    .channel-logo{
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .channel-selected{
        border: 1px solid #1797ff !important;
    }

    .card{
        border: 1px solid #aaabad;
    }

    .text-right{
        text-align: right;
    }
</style>