<template>
    <div class="form-check form-checkbox-outline form-check-primary mb-0" v-bind:style= "[!isCanChecked(checkbox) ? {paddingLeft: 0} : {}]">
        <template v-if="isCanChecked(checkbox)">
            <input type="checkbox" class="form-check-input" :id="'checkbox_' + checkbox.bayar_siswa_id" :value="checkbox.bayar_siswa_id" v-model="value">
            <label :for="'checkbox_' + checkbox.bayar_siswa_id" class="form-check-label"> <strong>{{checkbox.wajib_nama}}</strong></label>

        </template>
        <template v-else>
            <div style="display: inline-block;">
                <span><i class="fas fa-check-square"></i></span>
                <span style="padding-left: 7px;"><label class="form-check-label"> <strong>{{checkbox.wajib_nama}}</strong></label></span>
            </div>
        </template>
    </div>
</template>
<script>
import {mapGetters, mapState} from 'vuex'

export default {
    name : 'checkbox',
    props : ['checkbox'],
    
    methods : {
        isCanChecked(checkbox) {
            return (!checkbox.ui_props.harus_lunas && this.tagihanLocked.indexOf(checkbox.bayar_siswa_id) == '-1');
        }
    },
    computed : {
        ...mapGetters({
            tagihanBulanan : 'getTagihanBulanan',
            tagihanInsidentil : 'getTagihanInsidentil',
            allTagihan : 'getAllTagihan',
            uiConfig : 'getUiConfig'
        }),

        ...mapState({
            tagihanLocked : state => state.tagihan_locked
        }),
        value:{
            get(){
                return this.$store.state.tagihan_checked.indexOf(this.checkbox.bayar_siswa_id) > -1;
            },
            set(val){
                const mutation = val ? 'addToChecked' : 'removeFromChecked';
                
                let isTagihanBulanan = this.tagihanBulanan.filter((t) => t.bayar_siswa_id == this.checkbox.bayar_siswa_id)?.[0] ?? null;
                
                if(isTagihanBulanan) {
                    let id_wajib = isTagihanBulanan?.id_wajib ?? null;
                    
                    if(id_wajib) {
                        let when_wajib = new Date(isTagihanBulanan.bayar_siswa_tahun + '-' + isTagihanBulanan.bayar_siswa_bulan + '-01').getTime();
    
                        let tagihanBefore = [];
                        if(val) {
                            tagihanBefore = this.tagihanBulanan.filter((t) => {
                                let when_target_wajib = new Date(t.bayar_siswa_tahun + '-' + t.bayar_siswa_bulan + '-01').getTime();
                                return this.isCanChecked(t) && t.id_wajib == id_wajib  && when_target_wajib < when_wajib
                            });
                        } else {
                            tagihanBefore = this.tagihanBulanan.filter((t) => {
                                let when_target_wajib = new Date(t.bayar_siswa_tahun + '-' + t.bayar_siswa_bulan + '-01').getTime();
                                return this.isCanChecked(t) && t.id_wajib == id_wajib && when_target_wajib > when_wajib
                            });
                        }
        
                        const id = tagihanBefore.map((t) => t.bayar_siswa_id);
                        for(var key in id) {
                            this.$store.commit(mutation, id[key]);
                        }
                    }
                }
                // if(this.checkbox.hasOwnProperty('bayar_siswa_bulan') && this.checkbox.hasOwnProperty('bayar_siswa_tahun') && this.uiConfig.wajib_lunas_tunggakan_bulanan == true){
                //     var tagihanBefore
                //     if(val) {
                //         tagihanBefore = this.tagihanBulanan.filter((t) => t.ui_props.harus_lunas == false && new Date(t.bayar_siswa_tanggal_deadline).getTime() < new Date(this.checkbox.bayar_siswa_tanggal_deadline).getTime());
                //     } else {
                //         tagihanBefore = this.tagihanBulanan.filter((t) => t.ui_props.harus_lunas == false && new Date(t.bayar_siswa_tanggal_deadline).getTime() > new Date(this.checkbox.bayar_siswa_tanggal_deadline).getTime());
                //     }
    
                //     const id = tagihanBefore.map((t) => t.bayar_siswa_id);
                //     for(var key in id) {
                //         this.$store.commit(mutation, id[key]);
                //     }
                // }
                this.$store.commit(mutation, this.checkbox.bayar_siswa_id);
            },
        },
    }
}
</script>

<style scoped>
    .title-kewajiban{
        min-height: 50px;
        background: #b6b8b6;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #000;
        font-weight: bold;
        margin: 15px 0;
    }

    .card-channel{
        display: flex;
        flex-direction: column;
    }

    .channel-name{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-right: 2rem;
    }

    .channel-logo{
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .channel-selected{
        border: 2px solid #1797ff !important;
    }

    .card{
        border: 1px solid #aaabad;
    }

    .text-right{
        text-align: right;
    }
</style>