<template>
    <div class="row">
        <div class="col-12 text-center mb-3" style="font-weight: bold;">Pilih Channel Pembayaran</div>
        <div class="col-12 mb-0 card-channel">
            <template v-for="channel in data_channel">

                <div style="margin-bottom: 0.75rem;" :class="{'card' : true, 'channel-selected' : (channel_selected == channel.value) ? true : false}" @click="changeChannel(channel)" :key="channel.value">
                    <div class="card-body" style="padding: 0.50rem;">
                        <div class="form-group row mb-0">
                            <label class="col-4 col-form-label channel-logo" style="padding-left: 1.5rem;"><img :src="getImgUrl(channel.channel_img)" alt="" class="img-fluid"></label>
                            <label class="col-8 col-form-label channel-name" style="padding-right: 1.5rem;">{{channel.channel_name}}</label>
                        </div>
                        
                    </div>
                </div>
            </template>
            
            <div class="card card-bg-blue" v-if="ChannelSelectedFromStore && ChannelSelectedFromStore.value != 'unknown'">
                <div class="card-body" style="padding-top: 0.75rem; padding-bottom: 0.75rem;">
                    <div class="form-group row mb-0">
                        <label class="col-4 col-form-label">Channel</label>
                        <label class="col-8 col-form-label text-right"><strong>{{ChannelSelectedFromStore.channel_name}}</strong></label>
                    </div>
                    <div class="form-group row mb-0">
                        <label class="col-5 col-form-label">Fee Channel</label>
                        <label class="col-7 col-form-label text-right"><strong>{{(totalAdmin > 0) ? rupiah(totalAdmin) : 'GRATIS'}}</strong></label>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- end row -->
</template>
<script>
import {mapGetters} from 'vuex'
export default {
    name : 'channel-bayar',
    props : [],
    data() {
        return {
            
        }
    },

    methods : {
        getImgUrl(pet) {
            let images = require.context('@/assets/images/channel-bayar/', false, /\.png$/)
            return images('./' + pet)
        },
        changeChannel({value}){
            this.$store.commit('setChannelSelected', value)
        },
        rupiah : (number) => new Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR" }).format(number ?? 0).replace(",00", '')
    },

    computed : {
        ...mapGetters({
            ChannelSelectedFromStore : 'getChannelSelectedDetail',
            data_channel : 'getChannel',
            channel_selected : 'getChannelSelected',
            listAdminFee : 'getListAdminFee',
            listAvailableChannel : 'getListAvailableChannel',
            getDetailTagihanChecked : 'getDetailTagihanChecked',
            totalNominalTagihan : 'getTotTagihan',
            totalAdmin : 'getTotAdmin',
            totalTagihanWithAdmin : 'getTotTagihanWithAdmin',
            listMaxAmount : 'getListMaxAmount'
        }),
    }
}
</script>

<style scoped>
    .title-kewajiban{
        min-height: 50px;
        background: #b6b8b6;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #000;
        font-weight: bold;
        margin: 15px 0;
    }

    .card-channel{
        display: flex;
        flex-direction: column;
    }

    .channel-name{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-right: 2rem;
    }

    .channel-logo{
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .channel-selected{
        border: 2px solid #1797ff !important;
    }

    .card {
        border: 1px solid #aaabad;
    }

    .text-right{
        text-align: right;
    }
</style>